// created using https://icomoon.io

$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-account: "\e903";
$icon-cal: "\e904";
$icon-eue: "\e905";
$icon-filter: "\e906";
$icon-search: "\e907";
$icon-print: "\e908";
$icon-right: "\e909";
$icon-calendar: "\e90a";
$icon-close: "\e90b";
$icon-left: "\e90c";
$icon-top: "\e90d";
$icon-backet: "\e900";
$icon-brief: "\e901";
$icon-delete: "\e902";
$icon-tickets: "\e90e";
$icon-printdoc: "\e90f";
$icon-excel: "\e910";
$icon-pdf: "\e911";
$icon-cards: "\e912";
$icon-circle-check: "\e913";
$icon-circle-close: "\e914";
$icon-hand: "\e915";
$icon-money: "\e916";
$icon-bank: "\e917";
$icon-medal: "\e918";
$icon-reward: "\e919";
$icon-circle-plus: "\e91a";
$icon-reward: "\e919";
$icon-arr-left: "\e91b";
$icon-exchange: "\e91c";
$icon-logout: "\e91d";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tphqm5');
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tphqm5#iefix') format('embedded-opentype'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?tphqm5') format('truetype'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?tphqm5') format('woff'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?tphqm5##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-account {
    &:before {
      content: $icon-account;
    }
  }
  .icon-cal {
    &:before {
      content: $icon-cal;
    }
  }
  .icon-eue {
    &:before {
      content: $icon-eue;
    }
  }
  .icon-filter {
    &:before {
      content: $icon-filter;
    }
  }
  .icon-search {
    &:before {
      content: $icon-search;
    }
  }
  .icon-print {
    &:before {
      content: $icon-print;
    }
  }
  .icon-right {
    &:before {
      content: $icon-right;
    }
  }
  .icon-calendar {
    &:before {
      content: $icon-calendar;
    }
  }
  .icon-close {
    &:before {
      content: $icon-close;
    }
  }
  .icon-left {
    &:before {
      content: $icon-left;
    }
  }
  .icon-top {
    &:before {
      content: $icon-top;
    }
  }
  .icon-backet {
    &:before {
      content: $icon-backet;
    }
  }
  .icon-brief {
    &:before {
      content: $icon-brief;
    }
  }
  .icon-delete {
    &:before {
      content: $icon-delete;
    }
  }
.icon-tickets {
  &:before {
    content: $icon-tickets;
  }
}
.icon-printdoc {
  &:before {
    content: $icon-printdoc;
  }
}
.icon-excel {
  &:before {
    content: $icon-excel;
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf;
  }
}
.icon-cards {
  &:before {
    content: $icon-cards;
  }
}
.icon-circle-check {
  &:before {
    content: $icon-circle-check;
  }
}
.icon-circle-close {
  &:before {
    content: $icon-circle-close;
  }
}
.icon-hand {
  &:before {
    content: $icon-hand;
  }
}
.icon-money {
  &:before {
    content: $icon-money;
  }
}
.icon-bank {
  &:before {
    content: $icon-bank;
  }
}
.icon-medal {
  &:before {
    content: $icon-medal;
  }
}
.icon-reward {
  &:before {
    content: $icon-reward;
  }
}
.icon-circle-plus {
  &:before {
    content: $icon-circle-plus;
  }
}
.icon-arr-left {
  &:before {
    content: $icon-arr-left;
  }
}
.icon-exchange {
  &:before {
    content: $icon-exchange;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
