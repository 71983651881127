.report{
  &-table{
    .table{
      &-head{
        .table{
          &-col{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      &-body{
        color: $black;

        .table{
          &-col{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .report{
          &-cost{
            font-weight: 700;
          }
        }
      }
      &-foot{
        .table{
          &-row{
            justify-content: space-between;
            color: $black;
            font-weight: 700;
            box-shadow: none;
          }
        }
      }
      &-col{
        padding: 10px 10px;

        &:first-child{
          padding-left: 0;
        }

        &:last-child{
          padding-right: 0;
        }
      }
    }
    &.details{
      .report{
        &-user{
          flex: none;
          width: 165px;
        }

        &-method{
          width: 85px;
        }

        &-count{
          width: 70px;
        }

        &-cost{
          width: 95px;
        }

        &-date,
        &-time,
        &-number,
        &-event,
        &-type,
        &-price{
          display: block;
        }
      }
    }
  }

  &-user{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }

  &-date{
    width: 100px;
  }

  &-time{
    width: 80px;
  }

  &-number{
    width: 100px;
  }

  &-method{
    width: 205px;
  }

  &-event{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }

  &-type{
    width: 70px;
  }

  &-price{
    width: 100px;
  }

  &-count{
    width: 135px;
  }

  &-cost{
    width: 125px;
    text-align: right;
  }

  &-date,
  &-time,
  &-number,
  &-event,
  &-type,
  &-price{
    display: none;
  }

  &-total{
    text-align: right;
  }
}

@media (max-width: 1200px) {
  .report{
    &-table{
      &:not([class*="details"]){
        width: calc(100vw - 30px);
      }

      &.details{
        width: 1145px;
      }
    }
  }
}
