html,
body{
  height: 100%;
}
body{
  font-family: $mainFont;
  font-size: 14px;
  font-weight: $mainFontWeight;
  margin: 0;
  padding: 0;
  color: $main;
  min-height: 100%;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: $gray3;
  padding-top: 78px;
  overflow: hidden;
}

.container {
  width: 1145px;
  margin: 0 auto;
}

.content{
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  min-height: 340px;

  &-wrap{
    position: relative;
    display: flex;
    align-items: flex-start;
    max-width: 1145px;
    margin: 0 auto;
  }

  .ps__rail-x{
    display: none !important;
  }
}

.heading{
  position: relative;
  color: $black;
  max-width: 1145px;
  text-transform: uppercase;
  margin: 0 auto 15px;

  h1{
    font-size: 14px;
    font-weight: 600;
  }
}

.sidebar{
  position: relative;
  margin-bottom: 30px;

  &-right{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 280px;
    margin-left: 15px;

    &:before{
      content: '';
      position: absolute;
      background: #fff;
      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    }

    .sidebar{
      &-head{
        font-size: 14px;
        padding-right: 0;

        [class*="brief"]{
          font-size: 17px;
        }
      }

      &-body{
        padding-right: 0;
      }
    }
  }

  &-head{
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    color: $black;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 5px 15px;

    &:after{
      content: '';
      position: absolute;
      background: $main-02;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100vw;
    }

    .icon{
      width: 20px;
      margin-right: 10px;
    }

    [class*="tickets"]{
      font-size: 16px;
    }

    [class*="cards"]{
      font-size: 18px;
    }
  }

  &-body{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 20px 15px;
  }

  &-icon{
    position: fixed;
    display: none;
    top: 135px;
    right: 0;

    .btn{
      background: $orange;
      width: 40px;
      height: 40px;
      color: #fff;
      font-size: 16px;
      box-shadow: 0 2px 4px rgba(0,0,0,.08);
      padding: 0;
    }
  }
}

.align-items{
  &-stretch{
    align-items: stretch!important;
  }
}

.count{
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid $gray;
  border-radius: 4px;
  overflow: hidden;

  &-field{
    background: transparent;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    height: 40px;
    color: $black;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border: 0;
    padding: 0!important;
  }

  button{
    background: transparent;
    width: 25px;
    height: 40px;
    color: $blue;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover{
      color: $orange;
    }
  }
}

.ps__rail-y{
  background: transparent !important;
  width: 6px !important;
  opacity: 0.6 !important;
  z-index: 5;
  &:hover,
  &:focus,
  &.ps--clicking{
    opacity: 1 !important;
    .ps__thumb-y{
      background: $orange !important;
      width: 6px !important;
    }
  }
  .ps__thumb-y{
    background: $orange !important;
    right: 0 !important;
  }
}
.error-message{
  position: relative;
  color: #c00;
  font-size: 12px;
}


.ui{
  &.dropdown{
    &:not(.button){
      &>.default{
        &.text{
          color: #6e6e6e;
        }
      }
    }
    .menu{
      .active{
        &.item{
          //font-weight: 400;
        }
      }
      .selected{
        &.item{
          background: $orange;
          color: #fff;
        }
      }
    }
  }
  &.selection{
    &.dropdown{
      background: #fff;
      color: #353535;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ececec;
      &:focus{
        border-color: $orange;
      }
      .menu{
        max-height: 104px;
        width: calc(100% + 2px)!important;
        &::-webkit-scrollbar{
          background: transparent;
          width: 6px;
          padding: 0;
          margin: 0;
        }
        &::-webkit-scrollbar-track{
          background: transparent;
        }
        &::-webkit-scrollbar-button{
          display: none;
        }
        &::-webkit-scrollbar-thumb{
          background: $orange;
          opacity: 0.2;
        }
        &>.item{
          font-size: 14px;
          border: 0;
          padding: 10px 15px!important;
        }
      }
    }
    &.active{
      &.dropdown{
        border-color: $orange;
        &:hover{
          border-color: $orange;
          .menu{
            border-color: $orange;
          }
        }
        .menu{
          border-color: $orange;
        }
      }
    }
  }
}

.ticket-lists {
  position: absolute;
  left: 66px;
}
