.modal{
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;

  &-box{
    position: relative;
    background: #fff;
    width: 480px;
    padding: 30px 30px;
    border-radius: 4px;
  }
  &-close{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    transition: color 0.3s ease;
    cursor: pointer;
    &:hover{
      color: #F1A121;
    }
  }
}
