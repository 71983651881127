.paid{
  position: relative;

  &-heading{
    position: relative;
    color: $black;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &-table{
    position: relative;
    color: $black;

    &__row{
      position: relative;
      display: flex;
    }

    &__col{
      position: relative;
      line-height: 1.6;

      &:first-child{
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
      }

      &:last-child{
        width: 80px;
        font-weight: 700;
        text-align: right;
      }
    }
  }
}
