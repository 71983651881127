.order{
  &-table{
    position: relative;

    .table{
      &-row{
        &.active {
          background: #e8e8e8;  
        }
        position: relative;
        cursor: pointer;

        &:hover{
          .order{
            &-fio{
              text-decoration: underline;
            }
          }
        }

        .hover-btn{
          left: auto;
          right: 0;

          .icon{
            margin-right: 10px;
          }

          [class*="hand"]{
            font-size: 13px;
          }

          [class*="tickets"]{
            font-size: 16px;
          }
        }
      }
    }
  }

  &-number{
    width: 70px;
  }

  &-date{
    width: 110px;
    font-weight: 600;
  }

  &-time{
    width: 60px;
    font-weight: 600;
  }

  &-price{
    width: 100px;
    color: $black;
    font-weight: 600;
    text-align: right;
  }

  &-user{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    color: $blue;
    font-weight: 600;
  }

  &-method{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }

  &-fio{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    font-weight: 600;
  }

  &-email{
    width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-phone{
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30px;
    text-align: right;

    [class*="tickets"]{
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1199px){
  .order{
    &-phone{
      width: 130px;
    }
  }
}
