.footer {
    padding: 18px 30px;
    border-top: 1px solid $main-02;
    margin-top: auto;

    &__content {
        width: 1145px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
    }
}
