.table-wrap {
    position: relative;
    padding: 0 5px;
    margin: 0 -5px;
    overflow-x: auto;
    overflow-y: hidden;

    &.filter-open {
        .table-content {
            padding-left: 195px;
        }

        .nav-list {
            margin-left: 0;
        }

        .nav-btn {
            opacity: 0;
            visibility: hidden;
        }

        .filter {
            opacity: 1;
            height: auto;
            width: calc((100% - 780px)/2);
        }
    }
}

.table-content {
    width: 1145px;
    margin: 0 auto;
    transition: padding-left .3s;
}

.nav {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1145px;
    min-height: 60px;
    padding: 13px 0 10px;
    margin: 0 auto;

    &-list {
        display: flex;
        align-items: center;
        transition: margin-left .3s;
        flex: 1;
        padding-bottom: 5px;
        border-bottom: 1px solid $main-04;

        &:not(:first-child){
            margin-left: 195px;
        }

        &__item {
            font-weight: 600;
            margin: 0 20px;
            position: relative;
            transition: color .2s;

            &:first-child { margin-left: 5px; }
            &:last-child { margin-right: 5px; }

            &:after {
                content: '';
                position: absolute;
                left: -4px;
                right: calc(100% + 4px);
                bottom: -7px;
                height: 3px;
                background-color: $orange;
                transition: right .2s ease-out;
            }

            &:hover {
                &:after {
                    right: -4px;
                }
            }

            &.active {
                color: $black;

                &:after {
                    right: -4px;
                }
            }
        }
    }

    &-btn{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 13px;
        left: 0;
        height: 40px;
        color: $blue;
        font-weight: 600;
        transition: all 0.3s ease;
        cursor: pointer;
        z-index: 5;

        &.hidden{
            opacity: 0;
            visibility: hidden;
        }

        .btn{
            background: #fff;
            width: 40px;
            color: $blue;
            font-size: 18px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            padding: 0;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
                color: $orange;
            }

            &:not(:last-child){
                margin-right: 15px;
            }
        }
    }

    &-link{
        position: relative;
        margin-left: 35px;

        .btn{
            width: 155px;
            letter-spacing: -0.01em;
        }
    }

    &.fixed-width {
        max-width: 1145px;
        margin: 0 auto;
    }
}

.table {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    &-responsive{
        position: relative;
        overflow-x: auto;
    }

    &-head,
    &-body,
    &-foot{
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &-head{
        .table{
            &-row{
                color: $main;
                font-weight: 600;
            }
        }
    }

    &-foot{
        .table{
            &-row{
                background: transparent;
            }
        }
    }

    &-row {
        position: relative;
        display: flex;
        padding: 0 15px;
        background-color: $white;
        margin-bottom: 1px;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        transition: box-shadow .3s;

        &:hover {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

            .hover-btn {
                opacity: 1;
            }

            .event-name{
                text-decoration: underline;
            }
        }
    }

    &-col {
        padding: 10px 10px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        .dropdown{
            width: 100%;
        }
    }

    &-heading{
        position: relative;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 15px;

        h1{
            font-size: 14px;
            font-weight: 600;
        }
    }

    &-filter{
        position: relative;
        display: flex;
        align-items: center;
        color: $black;
        margin-bottom: 25px;

        &__title{
            position: relative;
            width: 100px;
        }

        &__search{
            position: relative;
            width: 265px;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;

            &:not(:last-child){
                margin-right: 30px;
            }

            input{
                background: transparent;
                height: 40px;
                padding-left: 40px;
            }

            .icon{
                position: absolute;
                left: 0;
                top: 0;
                font-size: 40px;
            }
        }

        &__date-range{
            position: relative;
            display: flex;
            align-items: center;

            &:not(:last-child){
                margin-right: 30px;
            }

            .input{
                &:not(:first-child){
                    margin-left: 14px;

                    &:before{
                        content: '-';
                        position: absolute;
                        left: -9px;
                        line-height: 40px;
                    }
                }
            }

            input{
                background: transparent;
                height: 40px;
                width: 125px;
                letter-spacing: -0.02em;
                padding: 0 8px;
            }

            .svg-icon{
                position: absolute;
                top: 50%;
                right: 8px;
                font-size: 16px;
                transform: translateY(-50%);
                pointer-events: none;
            }
        }

        &__checkbox{
            position: relative;
            display: inline-flex;
            align-items: center;

            &:not(:last-child){
                margin-right: 30px;
            }
        }

        &__button{
            position: relative;

            .btn{
                min-width: 165px;
            }

            .btn-link{
                min-width: auto;
            }
        }
    }
}


.event-date {
    width: 68px;
}

.event-time {
    font-weight: 600;
    width: 70px;
}

.event-name {
    font-weight: 600;
    color: $blue;
    flex: 1;
}

.event-location {
    width: 190px;
}

.event-city {
    width: 133px;
}

.event-type {
    width: 132px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .svg-icon {
        font-size: 12px;
    }
}

.pagination {
    position: relative;
    background: transparent!important;
    max-width: 1145px;
    display: flex!important;
    align-items: center!important;
    justify-content: space-between!important;
    border: 0!important;
    box-shadow: none!important;
    margin: 0 auto 40px!important;
    padding-top: 20px!important;

    &__links {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    &__item {
        width: 40px!important;
        height: 40px!important;
        vertical-align: middle!important;
        justify-content: center;
        min-width: 0!important;
        color: #6f6f6f!important;
        font-size: 14px!important;
        line-height: 38px!important;
        text-align: center!important;
        border: 1px solid $main-06!important;
        border-radius: 4px!important;
        padding: 0!important;
        margin-right: 1px!important;
        transition: border-color .2s!important;

        .svg-icon {
            font-size: 12px;
            transition: color .2s;
        }

        &.active {
            background: transparent!important;
            line-height: 36px!important;
            border-width: 2px!important;
            border-color: $orange!important;
            outline: none !important;
        }

        &:hover {
            background: transparent!important;
            border-color: $orange!important;

            .svg-icon {
                color: $orange;
            }
        }
    }

    &__actions{
        position: relative;
        display: flex;

        .btn{
            min-width: 165px;
            justify-content: flex-start;

            &:not(:last-child){
                margin-right: 30px;
            }

            .icon{
                font-size: 18px;
                margin-right: 17px;
            }
        }
    }
}

.hover-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5px;
    right: -15px;
    border-radius: 4px;
    background-color: $orange;
    color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    opacity: 0;
    transition: opacity .3s;

    .svg-icon {
        font-size: 12px;
    }
}

.filter {
    float: left;
    width: 0;
    background-color: $white;
    border-radius: 0 4px 4px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
    height: 0;
    opacity: 0;
    transition: all .3s;
    margin-right: 15px;

    &__content {
        width: 180px;
        padding-top: 20px;
    }

    &__header {
        width: 100%;
        border-radius: 0 4px 4px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $gray;
        padding: 15px 25px;
    }

    &__close {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: color .2s;
        color: $blue;

        .svg-icon {
            font-size: 12px;
            margin-right: 15px;
        }

        &:hover {
            color: $orange;
        }
    }

    &__section {
        margin-bottom: 20px;
        padding-bottom: 18px;
        border-bottom: 1px solid $gray;
        padding-right: 15px;
        padding-left: 2px;

        &-title {
            font-weight: 700;
            font-size: 16px;
            padding-bottom: 15px;
        }

        &-item {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: $black;
            margin-bottom: 20px;

            .checkbox {
                width: 20px;
                height: 20px;
                min-width: 20px;
            }
        }

        &:last-child {
            margin-bottom: 0;
            border: 0;
        }
    }

    &__switch {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 12px;
        padding-bottom: 17px;
    }

    input {
        height: 32px;
    }

    .period {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        font-size: 12px;

        .input {
            width: 140px;
        }

        .svg-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            font-size: 16px;
        }
    }

    &__search {
        margin-bottom: 19px;

        input {
            padding-left: 40px;
            font-size: 14px;
        }

        .svg-icon {
            font-size: 40px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__show-all {
        color: $orange;
        cursor: pointer;
        transition: color .3s;

        &:hover {
            color: $blue;
        }
    }
}
