//Font
$mainFont: 'Open Sans', sans-serif;

//Size
$mainFontSize: 14px;
$mainFontWeight: 400;
$mainLineHeight: 22px;

//colors
$main: #6F6F6F;
$main-02: rgba( $main, .2 );
$main-04: rgba( $main, .4 );
$main-06: rgba( $main, .6 );
$black: #353535;
$black-04: rgba(53, 53, 53, .4);
$orange: #F1A121;
$orange1: #ef9405;
$blue: #0066B2;
$gray: #ECECEC;
$gray1: #E2E2E2;
$gray2: #E5E5E5;
$gray3: #fafafa;
$white: #ffffff;
$green: #6AC259;
$red: #CA5858;
$red: #CB5858;