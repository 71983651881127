@media (max-width: 1199px) {
    .header {
        &__search {
            width: 45%;
        }

        &__content {
            padding: 0 15px;
            width: 100%;
        }
    }

    .nav{
        padding-left: 15px;
        padding-right: 15px;

        &-btn{
            left: 15px;
            font-size: 0;
        }

        &-list{
            &:not(:first-child) {
                margin-left: 55px;
            }

            &__item{
                margin: 0 10px;
            }
        }

        &-link{
            margin-left: 15px;
        }
    }

    .table-content{
        padding: 0 15px;

        .nav{
            padding-left: 0;
            padding-right: 0;

            &-btn{
                left: 0;
            }
        }
    }

    .table-wrap {
        padding: 0;
        margin: 0;

        &.filter-open .filter {
            width: 210px;
        }
    }

    .table{
        &-heading{
            padding: 0 15px;
        }

        &-filter{
            &__search{
                width: 240px;

                &:not(:last-child){
                    margin-right: 15px;
                }
            }

            &__date-range{
                &:not(:last-child){
                    margin-right: 15px;
                }
            }

            &__details{
                &:not(:last-child){
                    margin-right: 15px;
                }
            }
        }
    }

    .pagination {
        width: 100%;

        &__actions{
            .btn{
                &:not(:last-child){
                    margin-right: 15px;
                }
            }
        }
    }

    .footer {
        padding: 18px 15px;

        &__content {
            width: 100%;
        }
    }

    .content{
        overflow: hidden;

        &-wrap{
            padding: 0 15px;
        }
    }
}

@media (max-width: 991px){
    .nav{
        &-list{
            &__item{
                margin: 0 5px;
            }
        }
    }

    .sidebar{
        &-right{
            position: fixed;
            top: 0;
            right: -280px;
            height: 100%;
            transition: all 0.3s ease;
            z-index: 20;

            &.show{
                right: 15px;
            }
        }

        &-icon{
            display: block;
        }
    }

    .ticket{
        &-list{
            max-height: none;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
        }
    }
}

@media (max-width: 425px) {
    .login {
        width: calc(100vw - 30px);
        margin: 15px auto;
        padding: 30px 15px;
    }
}
