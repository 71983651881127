.sale{
  position: relative;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,.08);
  padding: 15px 25px 25px 25px;
  margin-bottom: 30px;

  &:not(:first-child){
    margin-top: 8px;
  }

  &-heading{
    position: relative;
    color: $black;
    font-weight: 600;
    text-transform: uppercase;
  }

  &-box{
    position: relative;
    max-width: 630px;
    padding: 20px 0;

    &:not(:last-child){
      border-bottom: 1px solid $main-02;
    }

    &__row{
      position: relative;
      display: flex;
      align-items: center;
      padding: 10px 0;
    }

    &__col{
      position: relative;
      padding: 0 10px;

      &:first-child{
        padding-left: 0;
      }

      &:last-child{
        padding-right: 0;
      }
    }
  }

  &-icon{
    width: 55px;
    font-size: 40px;
  }

  &-title{
    width: 195px;
  }

  &-field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 235px;

    input{
      width: 165px;
      height: 40px;
    }

    .icon{
      font-size: 20px;
    }

    [class*="check"]{
      color: $green;
    }

    [class*="close"]{
      color: $red;
    }
  }

  &-error {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    text-align: right;
    color: red;
  }

  &-price{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    text-align: right;
  }

  &-layout{
    position: relative;
    display: flex;
    align-items: center;
    max-width: 630px;
    padding: 30px 0;

    &:not(:last-child){
      margin-bottom: 25px;
    }

    &__title{
      position: relative;
      width: 160px;

      &:not(:last-child){
        padding-right: 10px;
      }
    }

    select{
      background: #fff;
      width: 265px;
      height: 40px;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ececec;
      padding: 0 15px;
      outline: none!important;
    }
  }

  &-button{
    position: relative;
    display: flex;
    align-items: center;

    .btn{
      &:not(:last-child){
        margin-right: 15px;
      }

      .icon{
        margin-right: 10px;
      }

      [class*="check"]{
        font-size: 20px;
        margin-right: 15px;
      }

      [class*="delete"]{
        font-size: 14px;
      }
    }
  }
}

.quick-sale{
  position: relative;
  margin-top: 15px;

  &:not(:last-child){
    margin-bottom: 30px;
  }

  &__list{
    position: relative;

    &:not(:last-child){
      margin-left: 15px;
    }
  }

  &-table{
    min-width: 850px;

    .table{
      &-row{
        align-items: center;
      }

      &-col{
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  &-title{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    color: $black;
    font-weight: 700;
  }

  &-price{
    width: 115px;
    color: $black;

    .color{
      padding: 0;
      margin: 0;
    }
  }

  &-type{
    width: 185px;

    select{
      background: #fff;
      width: 100%;
      height: 40px;
      color: #353535;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ececec;
      padding: 0 15px;
      outline: none!important;
    }
  }

  &-promocode{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 215px;

    input{
      height: 40px;
      width: 165px;
    }

    .icon{
      font-size: 20px;
    }

    [class*="check"]{
      color: $green;
    }

    [class*="close"]{
      color: $red;
    }
  }

  &-count{
    width: 90px;
  }

  &-total{
    width: 90px;
    color: $black;
    text-align: right;
    display: grid;
    &_red {
      text-decoration: line-through;
      color: red;
    }
  }

  &__button{
    position: relative;
    display: flex;
    justify-content: space-between;

    .btn{
      justify-content: left;
      width: 165px;

      .icon{
        margin-right: 15px;
      }

      [class*="plus"]{
        font-size: 20px;
      }
    }
  }
}

[class$=-placeholder] { 
  white-space: pre;
 }

 [class$=-ValueContainer] { 
  display: block;
  // border: 1px solid #ECECEC;
  height: 28px;
  border-radius: 4px;
  padding: 0 15px;
  width: 100%;
  color: #353535;
  -webkit-appearance: none;
  appearance: none;
 }

 [class$=-control] { 
  border: 1px solid #ECECEC!important;
 }

 .disabled_text {
   
 }