.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login {
    border: 1px solid $gray;
    border-radius: 4px;
    width: 420px;
    padding: 65px 30px 35px;

    &__logo {
        margin: 0 auto 55px;
        height: 45px;
    }

    &__title {
        text-align: center;
        font-weight: 700;
        color: $black;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 32px;
    }

    .input {
        margin-bottom: 25px;

        .svg-icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 36px;
            cursor: pointer;
            color: $main;
        }
    }

    &__remember {
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        &-text {
            cursor: pointer;
            color: $black;
            margin-left: 15px;
        }
    }

    .btn {
        min-width: 165px;
        height: 48px;
        margin: 0 0 30px;
        font-weight: 700;
    }
}

.reference {
    color: $black-04;
    font-size: 12px;
    line-height: 16px;
    transition: color .2s;

    &:hover {
        color: $orange;
    }
}
