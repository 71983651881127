.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    padding: 15px 0;


    &__content {
        width: 1145px;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    &__search {
        height: 40px;
        width: 655px;
        position: relative;

        input {
            height: 40px;
            padding-left: 40px;
            font-size: 14px;
        }

        .svg-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 38px;
        }
    }
}

.logo {
    height: 44px;
    margin-right: 30px;
}

.user {
    display: flex;
    margin-left: auto;
    padding-top: 5px;

    &__info {
        padding-top: 5px;
        padding-right: 15px;
    }

    &__name {
        line-height: 22px;
        color: $blue;
    }

    &__date {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
    }

    &__image {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 1px solid $blue;
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-icon {
            font-size: 38px;
        }
    }
    &__logout{
        position: relative;
        display: flex;
        align-items: center;
        height: 40px;
        color: $blue;
        font-size: 20px;
        margin-left: 10px;
        cursor: pointer;
    }
}
