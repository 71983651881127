.spinner{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75px;
  top: 35%;
  &-icon{
    position: relative;
    width: 50px;
    height: 50px;
    span{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      box-shadow: 0 -20px rgba(241,161,33, 0.125),
                  14px -14px rgba(241,161,33, 0.25),
                  20px 0px rgba(241,161,33, 0.375),
                  14px 14px rgba(241,161,33, 0.5),
                  0 20px rgba(241,161,33, 0.625),
                  -14px 14px rgba(241,161,33, 0.75),
                  -20px 0px rgba(241,161,33, 0.875),
                  -14px -14px rgba(241,161,33, 1);
      transform: translate(-50%, -50%);
      animation: spinner 1s linear infinite;
    }
  }
}

@keyframes spinner {
  12.5%{
    box-shadow: 0 -20px rgba(241,161,33, 0.125),
                14px -14px rgba(241,161,33, 0.25),
                20px 0px rgba(241,161,33, 0.375),
                14px 14px rgba(241,161,33, 0.5),
                0 20px rgba(241,161,33, 0.625),
                -14px 14px rgba(241,161,33, 0.75),
                -20px 0px rgba(241,161,33, 0.875),
                -14px -14px rgba(241,161,33, 1);
  }
  25%{
    box-shadow: 0 -20px rgba(241,161,33, 1),
                14px -14px rgba(241,161,33, 0.125),
                20px 0px rgba(241,161,33, 0.25),
                14px 14px rgba(241,161,33, 0.375),
                0 20px rgba(241,161,33, 0.5),
                -14px 14px rgba(241,161,33, 0.625),
                -20px 0px rgba(241,161,33, 0.75),
                -14px -14px rgba(241,161,33, 0.875);
  }
  37.5%{
    box-shadow: 0 -20px rgba(241,161,33, 0.875),
                14px -14px rgba(241,161,33, 1),
                20px 0px rgba(241,161,33, 0.125),
                14px 14px rgba(241,161,33, 0.25),
                0 20px rgba(241,161,33, 0.375),
                -14px 14px rgba(241,161,33, 0.5),
                -20px 0px rgba(241,161,33, 0.625),
                -14px -14px rgba(241,161,33, 0.75);
  }
  50%{
    box-shadow: 0 -20px rgba(241,161,33, 0.75),
                14px -14px rgba(241,161,33, 0.875),
                20px 0px rgba(241,161,33, 1),
                14px 14px rgba(241,161,33, 0.125),
                0 20px rgba(241,161,33, 0.25),
                -14px 14px rgba(241,161,33, 0.375),
                -20px 0px rgba(241,161,33, 0.5),
                -14px -14px rgba(241,161,33, 0.625);
  }
  62.5%{
    box-shadow: 0 -20px rgba(241,161,33, 0.625),
                14px -14px rgba(241,161,33, 0.75),
                20px 0px rgba(241,161,33, 0.875),
                14px 14px rgba(241,161,33, 1),
                0 20px rgba(241,161,33, 0.125),
                -14px 14px rgba(241,161,33, 0.25),
                -20px 0px rgba(241,161,33, 0.375),
                -14px -14px rgba(241,161,33, 0.5);
  }
  75%{
    box-shadow: 0 -20px rgba(241,161,33, 0.5),
                14px -14px rgba(241,161,33, 0.625),
                20px 0px rgba(241,161,33, 0.75),
                14px 14px rgba(241,161,33, 0.875),
                0 20px rgba(241,161,33, 1),
                -14px 14px rgba(241,161,33, 0.125),
                -20px 0px rgba(241,161,33, 0.25),
                -14px -14px rgba(241,161,33, 0.375);
  }
  87.5%{
    box-shadow: 0 -20px rgba(241,161,33, 0.375),
                14px -14px rgba(241,161,33, 0.5),
                20px 0px rgba(241,161,33, 0.625),
                14px 14px rgba(241,161,33, 0.75),
                0 20px rgba(241,161,33, 0.875),
                -14px 14px rgba(241,161,33, 1),
                -20px 0px rgba(241,161,33, 0.125),
                -14px -14px rgba(241,161,33, 0.25);
  }
  100%{
    box-shadow: 0 -20px rgba(241,161,33, 0.25),
                14px -14px rgba(241,161,33, 0.375),
                20px 0px rgba(241,161,33, 0.5),
                14px 14px rgba(241,161,33, 0.625),
                0 20px rgba(241,161,33, 0.75),
                -14px 14px rgba(241,161,33, 0.875),
                -20px 0px rgba(241,161,33, 1),
                -14px -14px rgba(241,161,33, 0.125);
  }
}
