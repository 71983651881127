.basket{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;

  &:not(:last-child){
    &:after{
      content: '';
      position: absolute;
      background: $main-02;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 1px;
    }
  }
}

.ticket{
  &-list{
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 400px;

    &:not(:last-child){
      margin-bottom: 35px;
    }

    &__box{
      position: relative;
      display: flex;
      background: #fff;
      height: 78px;
      border: 1px solid #E2E2E2;
      border-radius: 4px;
      overflow: hidden;

      &:not(:last-child){
        margin-bottom: 8px;
      }

      &-body{
        position: relative;
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        font-size: 12px;
        line-height: 1.9em;
        padding: 10px 10px;

        b{
          color: $black;
        }
      }

      &-group{
        position: relative;
        width: 50%;

        &:not(:first-child){
          color: $black;
          font-weight: 700;
          padding-left: 10px;
        }
      }

      &-seat{
        position: relative;
        line-height: 1.25;
        &:not(:last-child){
          margin-bottom: 8px;
        }
      }

      &-foot{
        position: relative;
        background: $gray3;
        width: 85px;
        line-height: 1.9em;
        border-left: 1px solid $gray1;
        border-radius: 0 4px 4px 0;
        padding: 10px 10px;
      }

      &-price{
        position: relative;
        color: $black;
        font-size: 12px;
        font-weight: 700;
      }

      &-delete{
        position: relative;
        display: flex;
        align-items: center;
        font-size: 10px;
        color: $blue;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover{
          color: $orange;
        }

        .icon{
          font-size: 13px;
          margin-right: 5px;
        }
      }
      &-checkbox{
        position: absolute;
        right: 8px;
        bottom: 8px;
      }
      .checkbox{
        background: #fff;
      }
    }

    &__action{
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin-top: auto;

      &-clear{
        position: relative;
        display: flex;
        align-items: center;
        font-size: 10px;
        color: $blue;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover{
          color: $orange;
        }

        .icon{
          font-size: 13px;
          margin-right: 5px;
        }
      }
    }
  }

}
