.scheme {
    position: relative;
    margin-bottom: 30px;

    &-svg {
        height: 620px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        background-color: $white;
        position: relative;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;

        & > div{
            width: auto!important;
            height: auto!important;
        }

        .spinner{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__legend {
        background-color: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        color: $black;

        &-header {
            display: flex;
            align-items: center;
            padding: 8px 15px;
            border-bottom: 1px solid $main-02;
        }
        &-body {
            display: none;
            flex-wrap: wrap;
            padding: 18px 0 7px;
        }

        &.open {
            .scheme__legend-body {
                display: flex;
            }

            .legend-switch {
                .svg-icon {
                    transform: rotate(0deg);
                }
            }

            .order{
                &-discount{
                    display: inline;
                    max-width: none;
                    white-space: normal;
                }
            }
        }
    }
    &-ticket{
        position: absolute;
        display: flex;
        background: #fff;
        top: 50%;
        left: 50%;
        color: $black;
        border-radius: 4px;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.24);
        transform: translate(-50%, -50%);
        overflow: hidden;
        z-index: 5;

        &__left{
            position: relative;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
            padding: 15px 25px;
        }

        &__title{
            position: relative;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        &__text{
            position: relative;
            font-size: 12px;
            line-height: 1.3;
            opacity: 0.4;
            margin-bottom: 15px;
        }

        &__count{
            position: relative;

            .count{
                width: 120px;
            }
        }

        &__right{
            position: relative;
            width: 165px;
            border-left: 1px solid $main-02;
            padding: 15px 25px;
        }

        &__table{
            position: relative;
            margin-bottom: 20px;
        }

        &__row{
            position: relative;
            display: flex;
            align-items: center;
            padding: 5px 0;
        }

        &__col{
            position: relative;
            padding: 0 5px;

            &:first-child{
                width: 50px;
                padding-left: 0;
            }

            &:last-child{
                font-weight: 700;
                padding-right: 0;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .color{
                padding: 0;
                margin: 0;
            }
        }

        &__button{
            position: relative;

            .btn{
                justify-content: flex-start;
                width: 100%;
                font-weight: 400;
                padding: 0 10px;

                .icon{
                    margin-right: 10px;
                }

                [class*="backet"]{
                    font-size: 15px;
                }
            }
        }
    }
}

.legend-switch {
    color: $blue;
    font-weight: 600;
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s;

    .svg-icon {
        font-size: 8px;
        margin-left: 8px;
        transform: rotate(180deg);
        transition: transform .2s;
    }

    &:hover {
        color: $orange;
    }
}

.color {
    display: flex;
    align-items: center;
    margin: 0 15px;
    padding-bottom: 9px;

    &-value {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 7px;
        display: inline-block;
    }
}

.order {
    margin: 0 15px;

    &-value {
        font-weight: 700;
    }

    &-discount {
        display: inline-block;
        vertical-align: top;
        max-width: 100px;
        color: $blue;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &:first-child {  margin-left: 0; }
    &:last-child {  margin-right: 0; }
}
.scheme-svg > div {
    width: auto !important;
    height: auto !important;
}
