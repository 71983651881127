.payment{
  position: relative;
  color: $main;
  font-size: 14px;
  text-align: right;

  &:not(:last-child){
    &:after{
      content: "";
      position: absolute;
      background: $main-02;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100vw;
    }
  }

  &-icon{
    position: absolute;
    top: 15px;
    left: 60px;
    color: $gray3;

    .icon{
      font-size: 82px;
    }

    [class*="backet"]{
      font-size: 82px;
    }

    [class*="tickets"]{
      font-size: 90px;
    }

    [class*="brief"]{
      font-size: 85px;
    }

    [class*="cards"]{
      font-size: 98px;
    }
  }

  &-count{
    position: relative;
    line-height: 1.6em;
    margin-bottom: 10px;
  }

  &-price{
    position: relative;
    color: $black;

    &:not(:last-child){
      margin-bottom: 20px;
    }

    b{
      display: block;
      font-size: 18px;
      font-weight: 700;
    }
  }

  &-discount{
    position: relative;
    line-height: 1.6em;

    &:not(:last-child){
      margin-bottom: 20px;
    }
  }

  &-layout{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child){
      margin-bottom: 35px;
    }

    select{
      background: #fff;
      width: 150px;
      height: 40px;
      color: $black;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid $gray;
      padding: 0 15px;
      outline: none !important;
    }

    option{
      height: 40px;
      color: $black;
    }
  }

  &-type{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &:not(:last-child){
      margin-bottom: 35px;
    }

    span{
      letter-spacing: -0.01em;
      margin-bottom: 5px;
    }

    select{
      background: #fff;
      width: 190px;
      height: 40px;
      color: $black;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid $gray;
      padding: 0 15px;
      outline: none !important;
    }

    option{
      height: 40px;
      color: $black;
    }
  }

  &-button{
    position: relative;

    .btn{
      justify-content: flex-start;
      width: 100%;

      .icon{
        font-size: 16px;
        margin-right: 15px;
      }

      [class*="print"]{
        font-size: 18px;
      }

      [class*="brief"]{
        font-size: 17px;
      }

      [class*="cards"]{
        font-size: 21px;
      }
    }
  }
}

