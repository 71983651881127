.input {
    position: relative;

    &-error{
        color: $red!important;
    }

    &-currency{
        position: relative;

        input{
            font-size: 14px;
            font-weight: 600;
            text-align: right;
            padding-right: 40px;
        }

        .currency{
            position: absolute;
            top: 10px;
            right: 10px;
            color: $main;
            pointer-events: none;
        }
    }
}

input {
    display: block;
    border: 1px solid $gray;
    height: 48px;
    border-radius: 4px;
    padding: 0 15px;
    width: 100%;
    color: $black;
    appearance: none;
    &[disabled]{
        background: transparent;
        opacity: 0.45;
    }
}

input[type='checkbox'] {
    display: none;
}

.checkbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 1px solid $gray1;
    border-radius: 4px;
    transition: border-color .2s;
    cursor: pointer;

    &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-image: url('../img/check.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: -2px;
        opacity: 0;
        transition: opacity .2s;
    }
    & + label{
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        line-height: 20px;
        padding-left: 15px;
        cursor: pointer;
    }
}

input[type='checkbox']:checked + .checkbox {
    background: $black;
    border-color: $black;
    &::after {
        opacity: 1;
    }
}

input[type='checkbox']:checked + .switch {
    background: #353535;
    &::after {
        background: $orange;
        left: 13px;
    }
}

.switch {
    display: block;
    width: 30px;
    height: 13px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, .16);
    transition: all 0.3s ease;
    position: relative;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        background: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.08);
        border-radius: 50px;
        width: 18px;
        height: 18px;
        left: 0;
        top: 50%;
        transform: translateY(-10px);
        transition: left .2s;
    }
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-weight: 600;
    padding: 0 15px;
    color: $white;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    transition: all .2s;
    cursor: pointer;
    font-size: 14px;
    outline: none!important;
    appearance: none!important;

    &:active{
        box-shadow: none;
    }

    &-orange {
        background-color: $orange;

        &:hover {
            background-color: $orange1;
            color: #fff;
        }
    }

    &-blue {
        background-color: $blue;

        &:hover {
            background-color: $orange;
        }
    }

    &-link{
        background: transparent;
        color: $blue;
        font-size: 14px;
        font-weight: 400;
        box-shadow: none;
        padding: 0;

        &:hover{
            color: $orange;
        }

        .icon{
            margin-right: 10px;
        }

        [class*="close"]{
            font-size: 12px;
        }
    }

    &-cancel{
        background: transparent;
        color: $red;
        font-size: 12px;
        box-shadow: none;
    }

    &.disabled,
    &[disabled]{
        background: #6F6F6F;
        opacity: 0.5;
        pointer-events: none;
        color: #fff;
    }
}
