.discount{
  &-table{
    min-width: 735px;
    padding-top: 15px;

    .table{
      &-foot{
        margin-bottom: 15px;
        .table{
          &-row{
            box-shadow: none;
          }
        }
      }

      &-row{
        align-items: center;
        min-height: 80px;
      }
    }
    .spinner{
      position: absolute;
      background: rgba(255, 255, 255, 0.7);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-check{
    width: 60px;

    &__all{
      position: relative;
      display: inline-block;
      color: $blue;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5;
      text-align: center;
      margin-left: -10px;
      cursor: pointer;
      transition: color 0.3s;

      &:hover{
        color: $orange;
      }
    }

    [type="checkbox"]{
      &:checked{
        & + .checkbox{
          background: $blue;
          border-color: $blue;
        }
      }
    }

    .checkbox{
      border-radius: 50%;
      margin-left: 5px;

      &:after{
        width: 14px;
        height: 14px;
        margin-top: -1px;
      }

    }
  }

  &-seats{
    display: flex;
    align-items: center;
    width: 200px;
    line-height: 22px;

    &__group{
      position: relative;
      width: 50%;

      &:not(:first-child){
        color: $black;
        font-weight: 700;
        padding-left: 10px;
      }

      b{
        color: $black;
        font-weight: 700;
      }
    }

    &__info{
      color: $black;
      font-size: 12px;
      line-height: 1.5;
    }
  }

  &-type{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;

    select{
      background: #fff;
      width: 100%;
      height: 40px;
      color: #353535;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ececec;
      padding: 0 15px;
      outline: none!important;
    }
  }

  &-promocode{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 235px;

    input{
      height: 40px;
      width: 165px;
    }

    .icon{
      font-size: 20px;
    }

    [class*="check"]{
      color: $green;
    }

    [class*="close"]{
      color: $red;
    }
  }

  &-price{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 120px;
    color: $black;
    line-height: 22px;
    text-align: right;

    b{
      font-weight: 700;
    }

    .old-price{
      color: $red;
      font-size: 12px;
      text-decoration: line-through;
    }
  }

  &-delete{
    position: relative;
    display: inline-flex;
    align-items: center;
    color: $red;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;

    .icon{
      font-size: 14px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1199px){
  .discount{
    &-check{
      width: 40px;
    }

    &-promocode{
      width: 175px;

      input{
        width: 120px;
      }
    }

    &-price{
      width: 100px;
      letter-spacing: -0.01em;
    }
  }
}

.content-list-order-height {
  min-height: 14vh;
}
